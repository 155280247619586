import { WaldoAPI } from '../../lib/api';
import { FeedbackBody, TalkToUsBody } from '../../types';

class FeedbackAPI extends WaldoAPI {
  async feedback(body: FeedbackBody): Promise<void> {
    return this.request({
      method: 'POST',
      path: '/v1/auth/feedback',
      body,
    });
  }

  async talkToUs(body: TalkToUsBody): Promise<void> {
    return this.request({
      method: 'POST',
      path: '/v1/auth/talk_to_us',
      body,
    });
  }
}

const api = new FeedbackAPI();

export const sendFeedback = async (body: FeedbackBody): Promise<void> =>
  api.feedback(body);

export const sendTalkToUs = async (body: TalkToUsBody): Promise<void> =>
  api.talkToUs(body);
