import { FunctionComponent } from 'react';

import { Box, styled } from '@mui/material';

import { Copyright } from '@/components/Copyright';

import { WaldoLogo } from '@/components/Icons/WaldoLogo';

import { PageSlide } from '../PageSlide';

const FooterStyled = styled(PageSlide)(({ theme }) => ({
  backgroundColor: '#0F0F0F',
  padding: theme.spacing(4, 2, 1, 2),

  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 4, 1, 4),
  },
}));

export const Footer: FunctionComponent = () => (
  <FooterStyled minHeight={0} pb={1} pt={4} px={4}>
    <Box color="#F7D371" mb={3}>
      <WaldoLogo />
    </Box>
    <Copyright />
  </FooterStyled>
);
