import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Grid, styled, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import { PageSlide } from '../PageSlide';

interface Item {
  name: string;
  words: string;
}

const defaultItems: Item[] = [
  {
    name: '- Strategist',
    words:
      "I love Waldo for getting data. I go directly to the datapoints whenever I use Waldo. For quantitative data it's super useful and will get me to what I need faster.",
  },
  {
    name: '- Growth Director',
    words:
      'I use Waldo for almost all my research. It makes life much better, much easier, because I can read the whole page of Google results without clicking in links.',
  },
  {
    name: '- Innovation Consultant',
    words:
      "Waldo is a very very useful tool. It's very helpful when trying to search for specific data points and information inside a document/article.",
  },
  {
    name: '- Consultant',
    words:
      'With Waldo I was able to add in the filters to narrow down the results to something much more specific. It was a game changer from that moment on.',
  },
  {
    name: '- Associate Director of Strategy',
    words:
      "Waldo has been a great addition to my research. When you're trying to find something, say in Thailand, a usual search only brings in the high level countries. Waldo is very useful for that.",
  },
  {
    name: '- Business Designer',
    words:
      "Waldo saves so much time when you're doing the research. It provides a better preview of the sources and I'm able to have a glance at a longer list of sources per click compared to the conventional Google search.",
  },
  {
    name: '- Research Manager',
    words:
      "I like Waldo very much – it's amazing. It allows me to save a lot of time.",
  },
  {
    name: '- Partner, Head of Strategy',
    words:
      "Waldo brings out results very fast. It's enhanced my ability to scan through search results at a much faster pace.",
  },
  {
    name: '- Innovation Strategist',
    words:
      "Waldo comes in handy especially when I'm dealing with certain kinds of requests – like when the client needs statistics. For general research, especially when you want to cross reference keywords and datapoints, it works very well.",
  },
  {
    name: '- Senior Strategist',
    words:
      "I see Waldo now as my go to when I have a very specific research need. Very specific criteria, datapoint, etc where I'd have to sift through to get it. My favorite way to use Waldo is the Datapoints tool.",
  },
];

const getGroupedItems = (items: Item[], perGroup = 2): Item[][] => {
  const groups: Item[][] = [];

  items.forEach((item) => {
    const lastIndex = groups.length - 1;

    if (!groups.length || groups[lastIndex].length === perGroup) {
      groups.push([item]);
    } else {
      groups[lastIndex].push(item);
    }
  });

  return groups;
};

const TestimonialsStyled = styled(PageSlide)(() => ({
  backgroundColor: '#D40A60',
}));

const CarouselStyled = styled(Carousel)(() => ({
  width: '100%',
}));

const TWO_COLUMN_MIN_WIDTH = 1050;

export const Testimonials: FunctionComponent = () => {
  const [twoColumn, setTwoColumn] = useState(true);
  const testimonials = getGroupedItems(defaultItems, twoColumn ? 2 : 1);

  useEffect(() => {
    const handleWindowSize = () => {
      setTwoColumn(window.innerWidth >= TWO_COLUMN_MIN_WIDTH);
    };

    // Add event listener
    window.addEventListener('resize', handleWindowSize);

    // Call handler right away so state gets updated with initial window size
    handleWindowSize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleWindowSize);
  }, [setTwoColumn]);

  return (
    <TestimonialsStyled justifyContent="center" minHeight={0} p={5}>
      <CarouselStyled
        autoPlay={false}
        height={twoColumn ? 250 : 400}
        animation="slide"
        activeIndicatorIconButtonProps={{
          className: '',
          style: { color: '#ECFBFB', opacity: 1 },
        }}
        indicatorContainerProps={{
          className: '',
          style: { marginTop: '4rem' },
        }}
        indicatorIconButtonProps={{
          className: '',
          style: { color: '#A7D3D3', opacity: 0.5 },
        }}
        navButtonsAlwaysInvisible
      >
        {testimonials.map((row, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid container justifyContent="center" key={index}>
            {row.map(({ name, words }, i) => (
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '500px !important',
                }}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                sm={12}
                md={row.length === 1 ? 12 : 6}
              >
                <Box maxWidth="27.5rem">
                  {/* <Box> */}
                  <Box mb={1}>
                    <Typography variant="h6">“{words}”</Typography>
                  </Box>
                  <Typography variant="h4">{name}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </CarouselStyled>
    </TestimonialsStyled>
  );
};
