import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import Image from './icon.svg';

const Icon = styled(Image)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

export const ArrowCircleRight: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    stroke="transparent"
    {...props}
  />
);
