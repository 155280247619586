import { FunctionComponent } from 'react';
import { styled, SvgIconProps } from '@mui/material';

import Image from './image.svg';

const Icon = styled(Image)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

export const Waldo: FunctionComponent<SvgIconProps> = (props) => (
  <Icon
    component={Image}
    width="160"
    height="40"
    viewBox="0 0 160 40"
    fill="none"
    {...props}
  />
);
