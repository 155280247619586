import { FunctionComponent } from 'react';

import { Box, styled } from '@mui/material';
import { PageSlide } from '../PageSlide';
import Logos from '@/components/molecules/CompaniesUsingWaldo';
import ThemeProvider from '@/components/providers/ThemeProvider';

const Companies = styled(PageSlide)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.common.black,
}));

const Title = styled(Box)(() => ({
  color: '#8B8B8B',
  fontFamily: 'Fraktion Mono',
  fontSize: '1.25rem',
  letterSpacing: '-0.04em',
  lineHeight: '1.5rem',
  textTransform: 'uppercase',
}));

export const CompaniesUsingWaldo: FunctionComponent = () => (
  <Companies minHeight={0} p={5}>
    <Title mb={2}>Saving Time At</Title>
    <ThemeProvider theme="light">
      <Logos />
    </ThemeProvider>
  </Companies>
);
