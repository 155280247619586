import { FunctionComponent } from 'react';

import { Typography, TypographyProps } from '@mui/material';

export const WaldoDescription: FunctionComponent<TypographyProps> = ({
  children,
  ...props
}) => (
  <Typography variant="h5" color="text.secondary" {...props}>
    {!!children && <>{children}</>}
    {!children && (
      <>
        Whether you’re working on a last minute pitch or a critical due
        diligence, Waldo's AI research assistant has you covered with fast,
        reliable, data.
      </>
    )}
  </Typography>
);
