import { FunctionComponent, ReactNode } from 'react';

import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  styled,
  ThemeProvider,
} from '@mui/material';
import classnames from 'classnames';

import { useCloseDialog, useDialogPage } from '../state/dialog';
import dark from '@/styles/themes/dark';
import { useColorMode } from '@/lib/utils/useColorMode';
import light from '@/styles/themes/light';
import { Waldo } from '../icons/Waldo';
import ButtonClose from './ButtonClose';

export interface DialogProps extends Omit<MuiDialogProps, 'title'> {
  actions?: ReactNode;
  children: Element | ReactNode;
  closeButton?: boolean;
  onClose?: () => void;
  overflow?: string;
  title?: ReactNode;
  titleColor?: string;
  variant?: 'light' | 'inverse';
}

const DialogStyled = styled(MuiDialog)(() => ({
  '&.isPage.MuiDialog-root': {
    paddingTop: 100,
  },
}));

const Logo = styled(Waldo)(() => ({
  width: 150,
  height: 100,
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  top: 20,
  zIndex: theme.zIndex.drawer,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Dialog: FunctionComponent<DialogProps> = ({
  actions,
  children,
  className = '',
  closeButton = true,
  onClose,
  open,
  overflow = 'overlay',
  title,
  titleColor,
  variant = 'light',
  ...props
}) => {
  const { isDarkMode } = useColorMode();
  const theme = variant === 'light' || isDarkMode ? light : dark;
  const isPage = useDialogPage();

  const closeDialog = useCloseDialog();
  const handleClose = () => {
    if (!isPage) {
      closeDialog();

      if (onClose) {
        onClose();
      }
    } else {
      window.location.href = '/app/search';
    }
  };

  return (
    <>
      {isPage && open && (
        <LogoContainer>
          <Logo />
        </LogoContainer>
      )}
      <ThemeProvider theme={theme}>
        <DialogStyled
          className={classnames({
            isPage,
            [className]: !!className,
          })}
          open={open}
          onClose={handleClose}
          {...props}
        >
          {title && (
            <DialogTitle color={titleColor || theme.palette.contrast?.dark}>
              {title}
            </DialogTitle>
          )}
          {closeButton && !isPage ? (
            <ButtonClose onClick={handleClose} />
          ) : null}
          <DialogContent style={{ overflow }}>{children}</DialogContent>
          {actions && <DialogActions>{actions}</DialogActions>}
        </DialogStyled>
      </ThemeProvider>
    </>
  );
};

export default Dialog;
