import { FunctionComponent } from 'react';

import { Box, BoxProps } from '@mui/material';

export const PageSlide: FunctionComponent<BoxProps> = ({
  children,
  ...props
}) => (
  <Box
    alignItems="center"
    display="flex"
    flex={1}
    flexDirection="column"
    minHeight="95vh"
    position="relative"
    {...props}
  >
    {children}
  </Box>
);
