import { Controller, useForm } from 'react-hook-form';
import { Box, DialogTitle, Input } from '@mui/material';
import { useMutation } from 'react-query';
import Dialog from '@/ext/app/ui/Dialog';
import { Button } from '@/components/atoms';
import { sendTalkToUs } from '@/ext/app/utils/sendFeedback';
import { useSetTalkToUsOpen, useTalkToUsOpen } from '@/ext/app/state/talkToUs';

export interface FormFields {
  name: string;
  company: string;
  email: string;
  phone: string;
  needs: string;
}

const TalkToUsDialog = () => {
  const open = useTalkToUsOpen();
  const setOpen = useSetTalkToUsOpen();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
  } = useForm<FormFields>({
    defaultValues: {
      name: '',
      company: '',
      email: '',
      needs: '',
      phone: '',
    },
    mode: 'onChange',
  });
  const {
    mutateAsync: requestTalkToUs,
    isLoading,
    isSuccess,
    reset: resetTalkToUs,
  } = useMutation((body: FormFields) => sendTalkToUs(body));

  const onSubmit = async (data: FormFields) => {
    await requestTalkToUs(data);
    reset();
  };

  const handleClose = () => {
    reset();
    resetTalkToUs();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableScrollLock
      title={isSuccess ? '' : 'Talk to us'}
      actions={
        isSuccess ? null : (
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            disabled={!isValid || isLoading}
          >
            Send
          </Button>
        )
      }
    >
      {isSuccess ? (
        <Box pt={0.5}>
          <DialogTitle>Thanks. We'll reach out to you asap!</DialogTitle>
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              render={(input) => (
                <Input
                  fullWidth
                  placeholder="Name"
                  {...input}
                  error={!!errors.name}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="company"
              control={control}
              rules={{
                required: true,
              }}
              render={(input) => (
                <Input
                  fullWidth
                  placeholder="Company"
                  {...input}
                  error={!!errors.company}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              }}
              render={(input) => (
                <Input
                  fullWidth
                  placeholder="Work email"
                  {...input}
                  error={!!errors.email}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="phone"
              control={control}
              render={(input) => (
                <Input
                  fullWidth
                  placeholder="Phone"
                  {...input}
                  error={!!errors.phone}
                />
              )}
            />
          </Box>
          <Box mt={0.5}>
            <Controller
              name="needs"
              control={control}
              rules={{
                required: true,
              }}
              render={(input) => (
                <Input
                  fullWidth
                  multiline
                  placeholder="Tell us about your current needs"
                  {...input}
                  error={!!errors.needs}
                />
              )}
            />
          </Box>
        </form>
      )}
    </Dialog>
  );
};

export default TalkToUsDialog;
