import { FunctionComponent, useState } from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

const StyledText = styled(Typography)(() => ({
  fontFamily: 'Fraktion Mono',
}));

export interface ComponentProps {
  delay?: number;
  words: string[];
}

export const Typewriter: FunctionComponent<ComponentProps & TypographyProps> =
  ({ delay = 2000, words, ...props }) => {
    const cursor = {
      show: true,
      blink: true,
      element: '_',
      hideWhenDone: false,
      hideWhenDoneDelay: 0,
    };
    const [key, setKey] = useState(0);
    const handleTypingDone = () => setKey(key + 1);

    return (
      <StyledText {...props}>
        <Typist cursor={cursor} key={key} onTypingDone={handleTypingDone}>
          {words.map((word) => (
            <span key={word}>
              {word}
              <Typist.Backspace count={word.length} delay={delay} />
            </span>
          ))}
        </Typist>
      </StyledText>
    );
  };
