import { FunctionComponent } from 'react';
import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import Image from './image.svg';

const Icon = styled(SvgIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
})) as unknown as typeof SvgIcon;

export const WaldoLogo: FunctionComponent<SvgIconProps> = (props) => (
  <Icon
    component={Image}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    {...props}
  />
);
