import { FunctionComponent } from 'react';

import { IconButton, styled } from '@mui/material';

import { Close } from '../icons/Close';

export interface ButtonCloseProps {
  onClick: () => void;
}

const Button = styled(IconButton)(() => ({
  position: 'absolute',
  right: 32,
  top: 24,
}));

const ButtonClose: FunctionComponent<ButtonCloseProps> = ({ onClick }) => (
  <Button onClick={onClick}>
    <Close />
  </Button>
);

export default ButtonClose;
